.App {
  text-align: center;
}

.Title {
  padding: 50px !important
}

.Alive {
  padding: 20px !important
}

.thisone {
  display: inline !important;
}

.answers {
  color: #6435c9 !important
}